<template>
  <div>
    <!-- <v-col
      md="12"
      sm="12"
      cols="12"
      class="align-self-start"
    >
      <v-card>
        <v-img
          src="poster/empire2.png"
          height="175"
        >
          <v-btn
            class="empire-btn"
            href="https://csgoempire.com/r/wortexesport"
          >
            <span>Open account!</span>
          </v-btn>
        </v-img>
      </v-card>
    </v-col> -->
    <v-row>
      <v-col
        md="8"
        lg="8"
        cols="12"
      >
        <v-card class="overflow-hidden">
          <v-row class="ma-0 h-full">
            <v-col
              cols="12"
              sm="12"
            >
              <v-card-title>
                <v-icon class="mr-1">
                  {{ icons.mdiAccountGroup }}
                </v-icon> Player draft
              </v-card-title>
              <v-card-text>
                Here, you can search for players who are looking to get picked up by team. You can also draft your profile to the public listing. This will help Baltic players get spotted by teams, reqruiters.<br><br>
                <span v-if="$store.getters['user/IsLoggedIn']">To get your draft up here you need to fill out your profile information and enable the draft <router-link
                  class="tc-green"
                  :to="'/profile/edit'"
                >here</router-link>.
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        md="4"
        lg="4"
        cols="12"
      >
        <a href="http://u.bgl.ee/gamerpay">
          <v-card>
            <v-img
              src="/poster/draft-gp.png"
              height="190"
            >
            </v-img>
          </v-card>
        </a>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col
        cols="12"
        md="2"
        class="pb-0"
      >
        <v-select
          v-model="filters.country"
          :items="countries"
          item-text="name"
          item-value="abbr"
          outlined
          dense
          label="Filter by Country"
          class="mt-2"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        class="pb-0"
      >
        <v-select
          v-model="filters.ingameroles"
          outlined
          dense
          label="Filter by role"
          class="mt-2"
          :items="['Rifler','In-game Leader','AWP','Lurker','Support','Any Role','Coach', 'Manager','Caster',]"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="pb-0"
      >
        <v-select
          v-model="filters.esea_placement"
          outlined
          dense
          label="Filter by ESEA placement"
          class="mt-2"
          :items="['Not Playing ESEA','ESEA Open','ESEA Intermediate','ESEA Main','ESEA Advanced']"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        class="pb-0"
      >
        <v-btn
          class="mt-2 darkbtn2"
          @click="resetFilters()"
        >
          Remove filters
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <statistics-card-with-images
          :statistics="ratingsOptions.statistics"
        ></statistics-card-with-images>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col
        v-for="player in players"
        :key="player.faceit_id"
        md="4"
        cols="12"
        class="align-self-start"
      >
        <v-card class="text-left pb-0">
          <div class="lb-1">
            <div class="top-1 mt-2 ml-2">
              <v-img
                width="20"
                height="100%"
                :src="'/flags/' + player.country + '.png'"
                class="user-flag-draft ml-1"
              >
              </v-img>

              <a class="player-draft-link">
                {{ player.nickname }}
              </a>
              <p
                v-if="player.esea_placement"
                class="mt-1 points-badge-esea"
                :class="player.esea_placement ? 'points-badge' : 'points-no-content-badge'"
              >
                {{ player.esea_placement }}
              </p>

              <div class="text-left mt-2 ml-1">
                Faceit Stats
              </div>
              <p
                v-if="player.role"
                class="points-badge player-role"
              >
                {{ player.role }}
              </p>
              <v-card-text class="no-padding">
                <p
                  :class="player.elo !== undefined ? 'points-badge-elo' : 'points-no-content-badge'"
                >
                  <span v-if="player.elo !== undefined">{{ player.elo }} ELO</span>
                </p>
                <p
                  class="mt-1"
                  :class="player.statistics.avg_kd ? 'points-badge' : 'points-no-content-badge'"
                >
                  <span v-if="player.statistics.avg_kd">{{ player.statistics.avg_kd }} KDR</span>
                </p>
                <br>
                <p
                  class="mt-1"
                  :class="player.statistics.match ? 'points-badge' : 'points-no-content-badge'"
                >
                  <span v-if="player.statistics.match">{{ player.statistics.match }} Matches</span>
                </p>
                <p
                  class="mt-1"
                  :class="player.statistics.win ? 'points-badge' : 'points-no-content-badge'"
                >
                  <span v-if="player.statistics.win">{{ player.statistics.win }} W
                  </span>
                </p>
                <p
                  class="mt-1"
                  :class="player.statistics.loss ? 'points-badge' : 'points-no-content-badge'"
                >
                  <span v-if="player.statistics.loss">{{ player.statistics.loss }} L</span>
                </p>
                <br>
                <div class="text-left mt-2 ml-1">
                  Role to fill
                </div>
                <p
                  v-for="(role, index) in player.ingameroles"
                  v-if="player.ingameroles"
                  :key="role + index"
                  class="role-badge"
                >
                  {{ role }}
                </p>
              </v-card-text>
            </div>
          </div>

          <v-expand-transition>
            <div v-if="selectedCard == player.faceit_id">
              <v-card-text>
                <v-card-text class="pl-0">
                  <div
                    v-if="player.team"
                    class="info-list mt-1"
                  >
                    <b class="tc-green">Previous team:</b> {{ player.team }}
                  </div>
                  <div
                    v-if="player.availability"
                    class="info-list"
                  >
                    <b class="tc-green">Available to play:</b> {{ player.availability }}
                  </div>
                  <div
                    v-if="player.languages"
                    class="info-list"
                  >
                    <b class="tc-green">Language:</b>
                    <v-img
                      v-for="(language, index) in player.languages"
                      :key="language + index"
                      width="15"
                      height="100%"
                      :src="'flags/' + language + '.png'"
                      class="draft-flag"
                    ></v-img>
                  </div>
                  <p
                    v-if="player.bio"
                    class="draft-text-badge mt-3"
                  >
                    {{ player.bio }}
                  </p>
                </v-card-text>
                <v-card-actions class="dense pl-0">
                  <v-btn
                    v-if="player.steam"
                    small
                    color="darkbtn2"
                    :href="'https://steamcommunity.com/profiles/' + (player.steam ? new SteamID(player.steam).getSteamID64() : '')"
                    target="_blank"
                  >
                    <v-icon
                      color="white"
                      size="14"
                      class="margin-bottom-draft mr-1"
                    >
                      {{ icons.mdiSteam }}
                    </v-icon> Steam
                  </v-btn>
                  <v-btn
                    v-if="player.discord_id"
                    small
                    color="darkbtn2"
                    :href="'https://discord.com/users/' + player.discord_id"
                    target="_blank"
                  >
                    <v-icon
                      color="white"
                      size="20"
                      class="margin-bottom-draft mr-1"
                    >
                      {{ icons.mdiDiscord }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="player.twitter_username"
                    small
                    color="darkbtn2"
                    :href="'https://twitter.com/' + player.twitter_username"
                    target="_blank"
                  >
                    <v-icon
                      color="white"
                      size="20"
                      class="margin-bottom-draft mr-1"
                    >
                      {{ icons.mdiTwitter }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="player.twitch_username"
                    small
                    color="darkbtn2"
                    :href="'https://twitch.tv/' + player.twitch_username"
                    target="_blank"
                  >
                    <v-icon
                      color="white"
                      size="20"
                      class="margin-bottom-draft mr-1"
                    >
                      {{ icons.mdiTwitch }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </div>
          </v-expand-transition>
          <v-btn
            small
            class="darkbtn2  profile-info pb-0"
            @click="selectedCard == player.faceit_id ? selectedCard = '' : selectedCard = player.faceit_id"
          >
            <v-icon class="">
              {{ selectedCard == player.faceit_id ? icons.mdiChevronUp : icons.mdiChevronDown }}
            </v-icon>
            Player Info
          </v-btn>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <div class="text-center">
          <infinite-loading @infinite="loadPlayers">
            <div slot="spinner"></div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import SteamID from 'steamid'
import InfiniteLoading from 'vue-infinite-loading'
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiEmoticonHappy,
  mdiEmoticonSad,
  mdiCommentTextMultiple,
  mdiTrophy,
  mdiSteam,
  mdiAccountBoxOutline,
  mdiFileDocumentEditOutline,
  mdiChartDonut,
  mdiAccountGroup,
  mdiTwitch,
  mdiTwitter,
  mdiDiscord,
} from '@mdi/js'

export default {
  components: {
    InfiniteLoading,
    StatisticsCardWithImages,
  },
  data() {
    return {
      page: 0,
      countries: [
        { name: 'Estonia', abbr: 'ee' },
        { name: 'Latvia', abbr: 'lv' },
        { name: 'Lithuania', abbr: 'lt' },
      ],
      filters: {
        country: null,
        ingameroles: null,
        esea_placement: null,
      },
    }
  },
  computed: {
    players() {
      return this.$store.getters['player_draft/GetAllPages']
        .map(x => x.data)
        .flat(2)
        .filter(player =>
          Object.entries(this.filters).every(kvp => {
            const [filterName, filterValue] = kvp

            return filterValue == null || player[filterName] == filterValue || player[filterName].includes(filterValue)
          }),
        )
    },
  },
  methods: {
    async loadPlayers($state) {
      this.page += 1
      if (!this.$store.getters['player_draft/HasPageLoaded'](this.page)) {
        await this.$store.dispatch('player_draft/loadPlayers', { page: this.page })
      }

      if (this.$store.getters['player_draft/GetCount']() == 10000000) $state.loaded()
      else $state.complete()
    },
    resetFilters() {
      this.filters = {
        country: null,
        role: null,
        esea_placement: null,
      }
    },
  },
  setup() {
    const selectedCard = ref('')
    const isDialogVisible = ref(false)
    const ratingsOptions = {
      statTitle: 'Players looking for team',
      statistics: '149',
    }

    return {
      selectedCard,
      isDialogVisible,
      ratingsOptions,

      // icons
      icons: {
        mdiChevronUp,
        mdiFileDocumentEditOutline,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiEmoticonHappy,
        mdiEmoticonSad,
        mdiCommentTextMultiple,
        mdiTrophy,
        mdiSteam,
        mdiAccountBoxOutline,
        mdiChartDonut,
        mdiAccountGroup,
        mdiTwitch,
        mdiTwitter,
        mdiDiscord,
      },
      SteamID,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';
// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}
</style>
